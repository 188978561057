// import Image from 'next/image'
import styled from 'styled-components';
import * as Styles from '../variables';

import { faFacebook, faGithub, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Body = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle at 22% 33%, rgba(255,0,162,1) 0%, rgba(73,0,144,1) 69%, rgba(23,0,46,1) 100%);
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: -50%;
  left: -50%;
  bottom: -50%;
  right: -50%;
  background: url('/anim-bg.png') repeat;
  transform: rotate(22deg);
`;

const Content = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const Blurb = styled.div`
  font-family: 'Fredoka One', 'Helvetica Neue', Helvetica, sans;
  font-size: 4.8rem;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 2em;
  color: ${Styles.Teal};
  width: 12em;
  text-shadow: 1px 1px 0px ${Styles.BlueShadow}, 2px 2px 0px ${Styles.BlueShadow}, 3px 3px 0px ${Styles.BlueShadow}, 4px 4px 0px ${Styles.BlueShadow};

  div {
    margin-top: .5em;
    color: ${Styles.Bright};
    font-size: 3rem;
    font-weight: normal;
    width: 11em;
    line-height: 1em;
  }
`;

const FloatingContent = styled.aside`
  position: relative;
`;

const SocialLinks = styled.footer`
  & h2 {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
`;

const SocialLink = styled.a`
  font-size: 2.8rem;
  margin-right: .5em;
  opacity: .75;
  transition: opacity .5s ease-in-out;
  color: white;

  &:hover {
    opacity: 1;
  }

  & > svg {
    height: 1em;
    width: 1em;
  }
`;

const Logo = styled.img`
  width: 30vw;
  height: 30vw;
  transform: rotate(-11deg);
  filter: drop-shadow(2px 2px 2px ${Styles.Dark});
`;

const Splash = () => {
  return (
    <Body>
      <head>
        <title>Wujibear</title>
        <meta name="description" content="Weird shit, made with love." />
      </head>

      <Content>
        <Logo src="/favicon.svg" alt="Wujibear" />

        <FloatingContent>
          <Blurb>
            Creator of weird things in Vancouver, BC. 
            <div>
              Paintings, Music, Film, and Code.
            </div>
          </Blurb>

          <SocialLinks>
            <h2>Social Links</h2>

            <SocialLink href="https://instagram.com/wujibear.studio" rel="Instagram" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </SocialLink>

            <SocialLink href="https://www.youtube.com/@wujibearstudio" rel="Youtube" target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </SocialLink>

            <SocialLink href="https://github.com/wujibear" rel="Github" target="_blank">
              <FontAwesomeIcon icon={faGithub} />
            </SocialLink>

            <SocialLink href="https://facebook.com/wujibear" rel="Facebook" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </SocialLink>

          </SocialLinks>

        </FloatingContent>
      </Content>

      <BackgroundWrapper>
        <BackgroundOverlay />
      </BackgroundWrapper>
    </Body>
  )
}

export default Splash
